import classNames from "classnames"
import { observer } from "mobx-react"
import { useEffect, useState } from "react"
import { useNewsItemFillHeight } from "../../../../hooks/useCalcHeight"
import { useRootStore } from "../../../../stores/RootStoreContext"
import NewsElementProps from "./NewsElementProps"
import "./NewsVideoElement.scss"

const rewriteEmbedURL = (url: string) => {
  const path = url.replace("https://youtu.be/", "")
  if (path.includes("?")) {
    return path.substring(0, path.indexOf("?"))
  } else {
    return path
  }
}

const NewsVideoElement = (props: NewsElementProps) => {
  const { mensaStore } = useRootStore()
  const fillHeight = useNewsItemFillHeight(mensaStore.isMensaMenueVisible)

  const hasFixedHeight = !!props.news.displayHeight
  const [videoHeight, setVideoHeight] = useState<number>()

  const url = props.news.title.trim()
  const videoId = url.startsWith("https://youtu.be/")
    ? rewriteEmbedURL(url)
    : url
        .replace("https://www.youtube.com/watch?v=", "")
        .replace("https://www.youtube.com/shorts/", "")

  useEffect(() => {
    const onResize = () => {
      const height = (window.innerWidth / 16) * 9
      setVideoHeight(height)
    }
    window.addEventListener("resize", onResize)
    onResize()
    return () => window.removeEventListener("resize", onResize)
  }, [])

  return (
    <div
      className={classNames("news-element", "element-video", { fill: props.news.fillScreen })}
      style={{
        height: hasFixedHeight
          ? `${props.news.displayHeight}%`
          : props.news.fillScreen
          ? `${fillHeight / 10}rem`
          : undefined,
      }}
    >
      <div
        className="video-inner"
        style={{
          height:
            videoHeight && !hasFixedHeight && !props.news.fillScreen
              ? `${videoHeight / 10}rem`
              : "100%",
        }}
      >
        <iframe
          width="100%"
          height="100%"
          seamless
          frameBorder={0}
          src={
            "https://www.youtube.com/embed/" +
            videoId +
            "?&autoplay=1&loop=1&mute=1&playlist=" +
            videoId
          }
          title="Video"
        />
      </div>
    </div>
  )
}

export default observer(NewsVideoElement)
