import Clock from "../../header/clock/Clock"
import "./ContentError.scss"

interface ContentErrorProps {
  error?: Error
}

const ContentError = (props: ContentErrorProps) => (
  <div className="content-error">
    <div className="error-inner">
      <Clock size={400} />
      <div className="error-explanation">
        <span>Der Inhalt kann zurzeit nicht geladen werden.</span>
        <span className="technical">{props.error?.message}</span>
      </div>
    </div>
  </div>
)

export default ContentError
