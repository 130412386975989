import { observer } from "mobx-react-lite"
import { useMemo } from "react"
import Constants from "../../../api/Constants"
import useCalcHeight from "../../../hooks/useCalcHeight"
import useNow, { UpdateFrequency } from "../../../hooks/useNow"
import useUpdateInterval from "../../../hooks/useUpdateInterval"
import { useRootStore } from "../../../stores/RootStoreContext"
import OverflowPager from "../../overflow-pager/OverflowPager"
import { MENSA_HEIGHT } from "../mensa/Mensa"
import "./News.scss"
import NewsEmptyState from "./NewsEmptyState"
import { isVisible } from "./NewsRestrictions"
import NewsElement from "./elements/NewsElement"

interface NewsProps {
  fullWidthLayout?: boolean
}

const NEWS_PAGING_DURATION_IN_SECONDS = 10

const HEADER_HEIGHT = 80
const APP_VERTICAL_PADDING = 52

const News = (props: NewsProps) => {
  const { newsStore, mensaStore, configurationStore } = useRootStore()
  const time = useNow(UpdateFrequency.Minute)

  const height = useCalcHeight(
    100,
    -HEADER_HEIGHT -
      APP_VERTICAL_PADDING -
      (mensaStore.isMensaMenueVisible ? MENSA_HEIGHT - 20 : 0) -
      40,
  )

  useUpdateInterval(
    () => newsStore.load(),
    [newsStore.load],
    Constants.UpdateIntervals.UPDATE_NEWS_ALL_SECONDS * 1000,
  )

  const visibleNews = useMemo(
    () =>
      newsStore.news.filter((news) => {
        const isVisibleOnAnyDisplay = news.displayMode !== "targeted"
        const isVisibleOnThisDisplay =
          news.displayMode === "targeted" &&
          configurationStore.display?.identifier &&
          news.limitedToDisplays?.includes(configurationStore.display?.identifier)

        return (
          news.isVisible &&
          (isVisibleOnAnyDisplay || isVisibleOnThisDisplay) &&
          isVisible(news, time)
        )
      }),
    [newsStore.news, configurationStore.display?.identifier, time],
  )
  const newsElements = visibleNews
    .filter((news) => news.type !== "callout")
    .map((news) => <NewsElement key={news.id} news={news} />)
  const newsCallouts = visibleNews
    .filter((news) => news.type === "callout")
    .map((news) => <NewsElement key={news.id} news={news} />)

  const newsLeftColumn = newsElements.filter((_, index) => index % 2 === 0)
  const newsRightColumn = newsElements.filter((_, index) => index % 2 === 1)

  const showColumns = newsElements.length > 1 && !props.fullWidthLayout

  if (visibleNews.length === 0) return props.fullWidthLayout ? <NewsEmptyState /> : null

  return (
    <div className="news-area" style={{ height: `${height / 10}rem` }}>
      <OverflowPager pagingDurationInSeconds={NEWS_PAGING_DURATION_IN_SECONDS}>
        {newsCallouts.length > 0 && <div className="news-callouts">{newsCallouts}</div>}
        {showColumns ? (
          <div className="news-columns">
            <div className="news-column column-left">{newsLeftColumn}</div>
            <div className="news-column column-right">{newsRightColumn}</div>
          </div>
        ) : (
          <div className="news-wide">{newsElements}</div>
        )}
      </OverflowPager>
    </div>
  )
}

export default observer(News)
