import React from "react"
import NewsElementProps from "./NewsElementProps"
import "./NewsItemElement.scss"

const NewsItemElement = (props: NewsElementProps) => (
  <div className="news-element element-news-item">
    <div className="news-header">
      <h3 className="card-title">{props.news.title}</h3>
    </div>
    <div className="news-content">
      <p className="card-lead">{props.news.lead}</p>
      <p className="card-content">{props.news.content}</p>
    </div>
  </div>
)

export default NewsItemElement
