import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import "./OverflowPager.scss"

interface OverflowPagerProps {
  children?: React.ReactNode
  snapToMultiplesOf?: number
  pagingDurationInSeconds?: number
}

const DEFAULT_PAGING_DURATION_IN_SECONDS = 16

const OverflowPager = (props: OverflowPagerProps) => {
  const [pages, setPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(0)

  const ref = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onUpdateNumberOfPages = () => {
      if (!ref.current || !innerRef.current) return

      const multiple = props.snapToMultiplesOf ?? 1
      const clientHeight = Math.floor(ref.current.clientHeight / multiple) * multiple

      if (innerRef.current.clientHeight < clientHeight) return setPages(1)

      let numberOfPages =
        ref.current.scrollHeight % clientHeight > 40
          ? Math.ceil(ref.current.scrollHeight / clientHeight)
          : Math.floor(ref.current.scrollHeight / clientHeight)
      if (numberOfPages < 1) numberOfPages = 1

      setPages(numberOfPages)
      if (currentPage > numberOfPages - 1) setCurrentPage(0)
    }
    window.addEventListener("resize", onUpdateNumberOfPages)
    onUpdateNumberOfPages()
    return () => window.removeEventListener("resize", onUpdateNumberOfPages)
  }, [props.children, props.snapToMultiplesOf, currentPage])

  useEffect(() => {
    const updateCurrentPage = (toPage: number) => {
      if (!ref.current || !innerRef.current) return

      const multiple = props.snapToMultiplesOf ?? 1
      const clientHeight = Math.floor(ref.current.clientHeight / multiple) * multiple

      innerRef.current.style.transform = `translateY(-${(clientHeight * toPage) / 10}rem)`
    }
    const onTurnPage = () => {
      let nextPage = currentPage + 1
      if (nextPage >= pages) nextPage = 0

      setCurrentPage(nextPage)
      updateCurrentPage(nextPage)
    }
    updateCurrentPage(currentPage)
    const timer = window.setInterval(
      onTurnPage,
      (props.pagingDurationInSeconds ?? DEFAULT_PAGING_DURATION_IN_SECONDS) * 1000,
    )
    return () => window.clearInterval(timer)
  }, [currentPage, pages, props.snapToMultiplesOf, props.pagingDurationInSeconds])

  return (
    <div className="overflow-pager">
      <div className="pager-content" ref={ref}>
        <div className="pager-inner" ref={innerRef}>
          {props.children}
        </div>
      </div>
      <div className="pager-indicators">
        {[...Array(pages)].map((_, index) => (
          <div
            key={index}
            className={classNames("indicator", {
              current: index === currentPage,
              single: pages === 1,
            })}
          />
        ))}
      </div>
    </div>
  )
}

export default OverflowPager
