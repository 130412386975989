import React from "react"

interface ContentAreaHeaderProps {
  icon?: string
  children?: React.ReactNode
}

const ContentAreaHeader = (props: ContentAreaHeaderProps) => (
  <div className="content-area-header">
    {props.icon && <img src={props.icon} alt="" />}
    <h2>{props.children}</h2>
  </div>
)

export default ContentAreaHeader
