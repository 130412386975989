import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import "./App.scss"
import ErrorBoundary from "./ErrorBoundary"
import APIError, { DebugErrorTypeMessages, ErrorType } from "./api/errors/APIError"
import ContentGrid from "./components/content-grid/ContentGrid"
import DebugOverlay from "./components/debug/debug-overlay/DebugOverlay"
import ConfigurationError from "./components/errors/configuration-error/ConfigurationError"
import Header from "./components/header/Header"
import ActionsManager from "./libs/actions/ActionsManager"
import { useRootStore } from "./stores/RootStoreContext"

interface AppProps {
  isDualScreenElement?: boolean
}

const App = (props: AppProps) => {
  const { configurationStore } = useRootStore()
  const [configurationError, setConfigurationError] = useState<APIError>()

  const actionsManager = useRef<ActionsManager | null>(null)
  const query = new URLSearchParams(window.location.search)

  useEffect(() => {
    const loadConfiguration = async () => {
      try {
        await configurationStore.load()
        configurationStore.log("Configuration loaded")
      } catch (error: any) {
        const errorDetails = []
        if ((error as APIError).type !== undefined) {
          errorDetails.push(
            `Type ${String(error.type)} (${
              DebugErrorTypeMessages[error.type as ErrorType] ?? "Unknown"
            })`,
          )
        }
        if ((error as APIError).axiosError) {
          errorDetails.push((error as APIError).axiosError?.code)
          errorDetails.push((error as APIError).axiosError?.response?.status ?? "No response")
          errorDetails.push(window.navigator.onLine ? "Browser is online" : "Internet unavailable")

          try {
            if ((error as APIError).axiosError?.response?.data)
              errorDetails.push(
                `Response: ${JSON.stringify(
                  (error as APIError).axiosError?.response?.data as any,
                )}`,
              )
          } catch {
            errorDetails.push("No JSON response")
          }

          if (error.message) errorDetails.push((error as APIError).message)
          configurationStore.log(
            "Configuration failed to load (Axios client). Error details: " +
              errorDetails.join(", "),
          )
        } else {
          if (error.message) errorDetails.push((error as APIError).message)
          configurationStore.log(
            "Configuration failed to load. Error details: " + errorDetails.join(", "),
          )
        }
        setConfigurationError(error)
      }
    }
    loadConfiguration()

    const configurationInterval = window.setInterval(() => loadConfiguration(), 50000)
    return () => window.clearInterval(configurationInterval)
  }, [configurationStore])

  useEffect(() => {
    actionsManager.current = new ActionsManager()
    return () => actionsManager.current?.destroy()
  }, [])

  const isDebugOverlayEnabled =
    configurationStore.display?.newsConfiguration?.includes("debug") === true || query.has("debug")

  if (configurationError)
    return (
      <div className="app">
        {isDebugOverlayEnabled && <DebugOverlay />}
        <ConfigurationError error={configurationError} />
      </div>
    )
  if (!configurationStore.hasConfigurationLoaded)
    return <div className="app">{isDebugOverlayEnabled && <DebugOverlay />}</div>

  if (configurationStore.display?.mode === "dual") {
    return (
      <div className="app dual-display">
        {isDebugOverlayEnabled && <DebugOverlay />}
        <ErrorBoundary>
          <Header />
          <div className="dual-screens">
            {configurationStore.display.dualScreens?.map((screen) => (
              <div key={screen} className="dual-screen">
                <iframe src={`/dual-screen/v/${screen}`} title="dual-screen-frame" />
              </div>
            ))}
          </div>
        </ErrorBoundary>
      </div>
    )
  }

  return (
    <div
      className={classNames("app", props.isDualScreenElement && "dual-display-element")}
      style={{ opacity: configurationStore.display?.isDarkened ? 0 : 1 }}
    >
      {isDebugOverlayEnabled && <DebugOverlay />}
      <ErrorBoundary>
        {!props.isDualScreenElement && <Header />}
        <ContentGrid />
      </ErrorBoundary>
    </div>
  )
}

export default observer(App)
