import { action, computed, makeObservable, observable, runInAction } from "mobx"
import API from "../api/API"
import Current from "../api/Current"
import Display from "../api/model/Display"
import DebugLogItem from "../components/debug/debug-overlay/DebugLogItem"
import RootStore from "./RootStore"
import Store from "./Store"

class ConfigurationStore extends Store {
  @observable display: Display | null = null
  @observable debugLogs: DebugLogItem[] = []

  private LOG_LIMIT = 30

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @computed
  public get hasConfigurationLoaded() {
    return this.display !== null
  }

  @action
  public log(message: string) {
    this.debugLogs.push({
      id: (Math.random() + 1).toString(36).substring(7),
      message,
      timestamp: Date.now(),
    })
    this.cleanOlderLogs()
  }

  @action
  private cleanOlderLogs() {
    if (this.debugLogs.length > this.LOG_LIMIT) {
      this.debugLogs = this.debugLogs.slice(0, this.LOG_LIMIT)
    }
  }

  public async load() {
    const display = await API.Configuration.currentDisplay()
    runInAction(() => {
      Current.configuration = API.Configuration.currentConfiguration()
      Current.display = display

      this.display = display
      this.onLoaded()
    })
  }

  public async onLoaded() {
    if (this.display?.mode === "news") this.rootStore.newsStore.load()
  }
}

export default ConfigurationStore
