import React from "react"
import Content from "./Content"
import "./ContentGrid.scss"

const HEADER_HEIGHT = 132
const CONTENT_TOP_MARGIN = 20

const ContentGrid = () => {
  const contentGrid = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const handleResize = () => {
      const height = window.innerHeight - HEADER_HEIGHT - CONTENT_TOP_MARGIN
      if (contentGrid.current) contentGrid.current.style.height = `${height}px`
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <div className="content-grid" ref={contentGrid}>
      <Content />
    </div>
  )
}

export default ContentGrid
