import { makeObservable, observable, runInAction } from "mobx"
import API from "../api/API"
import TimetableSchedule from "../api/model/Timetable"
import RootStore from "./RootStore"
import Store from "./Store"

class TimetableStore extends Store {
  @observable schedule: TimetableSchedule | null = null
  @observable loadingError: Error | null = null
  @observable isFetching = false

  private static FIXED_DEMO_DATE: Date | null = null

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  public async load() {
    if (this.isFetching) return
    try {
      runInAction(() => {
        this.isFetching = true
      })
      const schedule = await API.Timetable.scheduleForDate(
        TimetableStore.FIXED_DEMO_DATE ?? new Date(),
      )
      runInAction(() => {
        this.schedule = schedule
        this.loadingError = null
        this.rootStore.configurationStore.log("Updated schedule")
      })
    } catch (error: any) {
      this.loadingError = error
      this.rootStore.configurationStore.log("Could not load schedule: " + (error as Error).message)
      this.rootStore.configurationStore.log(
        "Stack trace: " + (error as Error).stack ?? "Unavailable",
      )
      console.log(error)
    } finally {
      runInAction(() => {
        this.isFetching = false
      })
    }
  }
}

export default TimetableStore
