import { DateTime } from "luxon"
import News from "../../../api/model/News"

export const isVisible = (news: News, now: DateTime) => {
  const hasPassedWeekdayRestriction =
    !news.limitedToWeekdays?.length || news.limitedToWeekdays?.includes(new Date().getDay())
  const hasPassedTimeRestriction = isVisibleDuringTime(news, now)
  const hasPassedStartDateRestriction =
    !news.dateRestrictionStart || news.dateRestrictionStart <= now.toMillis()
  const hasPassedEndDateRestriction =
    !news.dateRestrictionEnd || news.dateRestrictionEnd >= now.toMillis()

  return (
    hasPassedWeekdayRestriction &&
    hasPassedTimeRestriction &&
    hasPassedStartDateRestriction &&
    hasPassedEndDateRestriction
  )
}

export const isVisibleDuringTime = (news: News, now: DateTime) => {
  if (news.timeRestrictionStart) {
    const start = parseTimeRestriction(news.timeRestrictionStart, now)
    if (now < start) return false
  }
  if (news.timeRestrictionEnd) {
    const end = parseTimeRestriction(news.timeRestrictionEnd, now)
    if (now >= end) return false
  }
  return true
}

export const parseTimeRestriction = (time: string, now: DateTime) => {
  const [hour, minute] = time.split(":").map((value) => parseInt(value))
  const timedDate = DateTime.fromMillis(now.toMillis()).set({
    hour,
    minute,
    second: 0,
    millisecond: 0,
  })
  return timedDate
}
