import Configuration from "./model/Configuration"
import Display from "./model/Display"

class Current {
  public static configuration: Configuration | null
  public static display: Display | null

  public static deviceId: string = this.getCurrentDeviceId()

  public static get school() {
    return this.configuration?.school
  }

  private static getCurrentDeviceId() {
    const deviceId = localStorage.getItem("deviceId")
    if (deviceId) return deviceId

    const newDeviceId = (Math.random() + 1).toString(36).substring(2)
    localStorage.setItem("deviceId", newDeviceId)
    return newDeviceId
  }
}

export default Current
