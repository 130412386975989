import React from "react"
import { Menue } from "../../../api/model/Mensa"

interface MensaMenueProps {
  menue: Menue
}

const MensaMenue = (props: MensaMenueProps) => (
  <div className="menue">
    {!!props.menue.type && <span className="type">{props.menue.type}</span>}
    <h3 className="title">{props.menue.text.join(" ")}</h3>
    <p className="details">{props.menue.extra.join(" ")}</p>
    <span className="price">{props.menue.price}</span>
  </div>
)

export default MensaMenue
