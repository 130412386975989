import axios from "axios"
import Constants from "./Constants"
import Current from "./Current"

class PresenceAPI {
  public async markPresence(): Promise<void> {
    if (!Current.configuration) return
    if (document.location.hostname === "localhost" || Current.deviceId === "development") return
    try {
      await axios.post(
        `${Constants.ServiceHost}/displays/${Current.school}/${Current.configuration.identifier}/presence`,
        {
          identifier: Current.deviceId,
          userAgent: navigator.userAgent,
          version: Constants.Version,
          data: {
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
          },
        },
      )
    } catch (error: any) {
      // We don't care. Not that important and we'll retry soon enough anyway.
    }
  }
}

export default PresenceAPI
