import { useEffect, useState } from "react"
import { MENSA_HEIGHT } from "../components/content/mensa/Mensa"

/**
 * This is a hook for automatically calculating the height of a component
 * through JavaScript.
 *
 * This is necessary because of the archaic Samsung browser that we need
 * to support (smart displays), which doesn't have proper support
 * for vh and vw units.
 */
const useCalcHeight = (vh: number, offset = 0) => {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const calcHeight = () => {
      setHeight(window.innerHeight * (vh / 100) + offset)
    }
    calcHeight()
    window.addEventListener("resize", calcHeight)
    return () => window.removeEventListener("resize", calcHeight)
  }, [vh, offset])

  return height
}

export const useNewsItemFillHeight = (isMensaVisible?: boolean) =>
  useCalcHeight(100, -185 - (isMensaVisible ? MENSA_HEIGHT - 20 : 0))

export default useCalcHeight
