import classNames from "classnames"
import { observer } from "mobx-react"
import Constants from "../../../api/Constants"
import Current from "../../../api/Current"
import { useRootStore } from "../../../stores/RootStoreContext"
import "./DebugOverlay.scss"

const DebugOverlay = () => {
  const { configurationStore } = useRootStore()
  const logs = [...configurationStore.debugLogs].reverse()

  return (
    <div className="debug-overlay">
      <div
        className={classNames("brand", {
          "type-news": configurationStore.display?.mode === "news",
          "type-display": configurationStore.display?.mode === "timetable",
        })}
      />
      <h3 className="display-name">{configurationStore.display?.depiction ?? "Display"}</h3>
      <div className="info-row">
        <div className="device-info">{Current.deviceId}</div>
        <div className="software-info">Version {Constants.Version}</div>
        <div
          className={classNames("configuration-info", {
            "is-loaded": configurationStore.hasConfigurationLoaded,
          })}
        >
          {configurationStore.hasConfigurationLoaded
            ? `Konfiguration ${configurationStore.display?.identifier}`
            : "Konfiguration nicht geladen"}
        </div>
      </div>
      <div className="logs">
        {logs.map((log) => (
          <div key={log.id} className="log-item">
            <div className="time">{new Date(log.timestamp).toLocaleTimeString()}</div>
            <div className="message">{log.message}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(DebugOverlay)
