import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useNewsItemFillHeight } from "../../../../hooks/useCalcHeight"
import { useRootStore } from "../../../../stores/RootStoreContext"
import NewsElementProps from "./NewsElementProps"
import "./NewsImageElement.scss"

const NewsImageElement = (props: NewsElementProps) => {
  const { mensaStore } = useRootStore()
  const height = useNewsItemFillHeight(mensaStore.isMensaMenueVisible)
  return (
    <div
      className={classNames("news-element", "element-image", { fill: props.news.fillScreen })}
      style={{ height: props.news.fillScreen ? `${height / 10}rem` : undefined }}
    >
      <img src={props.news.title} alt="" />
    </div>
  )
}

export default observer(NewsImageElement)
