import { AxiosError } from "axios"

export enum ErrorType {
  ConfigurationArgumentsMissing,
  BadArguments,
  Misconfigured,
  NoConnectivity,
  LoadingFailed,
}

export const DebugErrorTypeMessages: Record<ErrorType, string> = {
  [ErrorType.ConfigurationArgumentsMissing]: "Configuration arguments missing",
  [ErrorType.BadArguments]: "Bad arguments",
  [ErrorType.Misconfigured]: "Misconfigured",
  [ErrorType.NoConnectivity]: "No connectivity",
  [ErrorType.LoadingFailed]: "Loading failed",
}

class APIError extends Error {
  public readonly type: ErrorType
  public readonly axiosError?: AxiosError

  constructor(type: ErrorType, message?: string, axiosError?: AxiosError) {
    super(message)
    this.type = type
    this.axiosError = axiosError
  }
}

export default APIError
