import { DateTime } from "luxon"
import { useEffect, useState } from "react"

export enum UpdateFrequency {
  High = 200,
  EverySecond = 1000,
  Low = 10000,
  Minute = 60000,
}

const DEBUG_HOURS_OFFSET = 0
const DEBUG_MINUTES_OFFSET = 0

const getCurrentDate = () => {
  const now = new Date()
  if (DEBUG_HOURS_OFFSET || DEBUG_MINUTES_OFFSET) {
    now.setHours(now.getHours() + DEBUG_HOURS_OFFSET)
    now.setMinutes(now.getMinutes() + DEBUG_MINUTES_OFFSET)
  }
  return now
}

const useNow = (updateFrequency = UpdateFrequency.EverySecond) => {
  const [now, setNow] = useState<Date>(getCurrentDate())
  const date = DateTime.fromJSDate(now)

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(getCurrentDate())
    }, updateFrequency)
    return () => clearInterval(interval)
  }, [updateFrequency])

  return date
}

export default useNow
