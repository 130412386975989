import React, { useCallback, useEffect } from "react"

const useUpdateInterval = (
  callback: () => void,
  deps: React.DependencyList,
  interval: number,
  initialCall: boolean = true,
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackFunction = useCallback(callback, deps)

  useEffect(() => {
    if (initialCall) callbackFunction()
    const intervalId = setInterval(callbackFunction, interval)
    return () => clearInterval(intervalId)
  }, [callbackFunction, interval, initialCall])
}

export default useUpdateInterval
