import { observer } from "mobx-react"
import BrandedGBWLogo from "../../assets/brand/branded-gbw.png"
import SALIORELNewsLogo from "../../assets/brand/saliorel-news-logo.svg"
import { useRootStore } from "../../stores/RootStoreContext"

interface LogoProps {
  large?: boolean
}

const Logo = (props: LogoProps) => {
  const { configurationStore } = useRootStore()
  return (
    <img
      src={configurationStore.display?.school === "gbw" ? BrandedGBWLogo : SALIORELNewsLogo}
      alt="SALIOREL"
      height={configurationStore.display?.school === "gbw" ? (props.large ? 120 : 60) : undefined}
    />
  )
}

export default observer(Logo)
