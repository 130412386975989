import { DateTime } from "luxon"
import Constants from "../../../api/Constants"
import { ClassConfiguration } from "../../../api/model/Display"
import TimetableSchedule, { ScheduleLesson } from "../../../api/model/Timetable"
import DateUtilities from "../../../utility/DateUtilities"
import StringUtilities from "../../../utility/StringUtilities"

class TimetableConfiguration {
  private configuration: ClassConfiguration[]
  private classLocationFilter: string[] | undefined

  private school: string

  constructor(
    classConfiguration: ClassConfiguration[],
    classLocationFilter: string[] | undefined,
    school: string,
  ) {
    this.configuration = classConfiguration
    this.classLocationFilter = classLocationFilter
    this.school = school
  }

  private defaultConfiguration(at: DateTime) {
    if (this.school === "gbw") {
      let configurations: ClassConfiguration[] = []
      Constants.ContentDisplayTimes.GBW_TIMETABLE_DEFAULT_CONFIGS.forEach((defaultConfig) => {
        if (DateUtilities.isDateAfterTime(at, defaultConfig.start))
          configurations = defaultConfig.configurations
      })
      return configurations
    }
    return []
  }

  public schedulers(from: TimetableSchedule, at: DateTime) {
    const configuration = this.hasAnyRestrictions
      ? this.configuration
      : this.defaultConfiguration(at)

    return configuration.map((config) => {
      const slicedConfiguration = new TimetableConfiguration(
        [config],
        this.classLocationFilter,
        this.school,
      )
      return {
        configuration: config,
        schedule: slicedConfiguration.filteredSchedule(from),
        times: TimetableConfiguration.timesForConfiguration(config),
      }
    })
  }

  public filteredSchedule(from: TimetableSchedule) {
    const schedule: TimetableSchedule = {}
    for (const className in from) {
      if (!from.hasOwnProperty(className)) continue

      const isAllowed = !this.hasAnyRestrictions || this.isClassIncluded(className, from)
      if (isAllowed) schedule[className] = from[className]
    }
    return schedule
  }

  public get hasAnyRestrictions() {
    return this.configuration.length > 0
  }

  public isClassIncluded(className: string, from: TimetableSchedule) {
    return (
      this.configuration.some((configuration) =>
        this.doesClassNameMatchConfiguration(className, configuration),
      ) && this.isClassContextuallyRelevant(className, from[className])
    )
  }

  private isClassContextuallyRelevant(
    _className: string,
    classSchedule: { [time: string]: ScheduleLesson[] },
  ) {
    const lessons = Object.values(classSchedule).reduce((a, b) => a.concat(b), [])
    if (!lessons.length) return false

    if (this.classLocationFilter?.length) {
      return lessons.some((lesson) =>
        this.classLocationFilter!.some((location) => lesson.room.startsWith(location)),
      )
    }
    return true
  }

  private static timesForConfiguration(configuration: ClassConfiguration) {
    switch (configuration) {
      case ClassConfiguration.ShowBaseEducationClassesGBW:
        return Constants.TimetableTimes.GBW_GB
      case ClassConfiguration.ShowFurtherEducationClassesGBW:
        return Constants.TimetableTimes.GBW_WB
      default:
        return Constants.TimetableTimes.GBW_WB
    }
  }

  public highlightForClass(className: string) {
    if (this.school === "gbw") {
      const startsWithFurtherEducationPrefix = StringUtilities.startsWithAny(
        className,
        Constants.ClassConfigurationPrefixes.GBW_FURTHER_EDUCATION,
      )
      return startsWithFurtherEducationPrefix ? "turquoise" : null
    }
    return null
  }

  private doesClassNameMatchConfiguration = (
    className: string,
    configuration: ClassConfiguration,
  ) => {
    if (this.school === "gbw") {
      const startsWithFurtherEducationPrefix = StringUtilities.startsWithAny(
        className,
        Constants.ClassConfigurationPrefixes.GBW_FURTHER_EDUCATION,
      )
      if (!configuration || configuration === ClassConfiguration.Any) {
        return true
      } else if (configuration === ClassConfiguration.ShowFurtherEducationClassesGBW) {
        return startsWithFurtherEducationPrefix
      } else {
        return !startsWithFurtherEducationPrefix
      }
    }
    return false
  }
}

export default TimetableConfiguration
