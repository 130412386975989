import { makeObservable, observable, runInAction } from "mobx"
import API from "../api/API"
import Event from "../api/model/Agenda"
import RootStore from "./RootStore"
import Store from "./Store"

class AgendaStore extends Store {
  @observable events: Event[] | null = null
  @observable loadingFailed = false

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  public async load() {
    try {
      const events = await API.Agenda.currentEvents()
      runInAction(() => {
        this.events = events
        this.loadingFailed = false
      })
    } catch (error) {
      this.loadingFailed = true
      this.rootStore.configurationStore.log("Could not load agenda: " + (error as Error).message)
      console.log(error)
    }
  }
}

export default AgendaStore
