import axios, { AxiosError } from "axios"
import Constants from "./Constants"
import Current from "./Current"
import APIError, { ErrorType } from "./errors/APIError"
import MensaMenues from "./model/Mensa"

class MensaAPI {
  public async currentMenues(): Promise<MensaMenues> {
    try {
      const response = await axios.get(`${Constants.CoreHost}/mensa/sv/${Current.school}`)
      if (response.status !== 200 || !response.data.day_0) throw new Error(response.data.message)
      return response.data as MensaMenues
    } catch (error: any) {
      throw new APIError(ErrorType.LoadingFailed, (error as AxiosError).message)
    }
  }
}

export default MensaAPI
