import RootStore from "./RootStore"

abstract class Store {
  rootStore: RootStore

  constructor(root: RootStore) {
    this.rootStore = root
  }
}

export default Store
