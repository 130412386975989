import { observer } from "mobx-react-lite"
import Constants from "../../../api/Constants"
import useNow, { UpdateFrequency } from "../../../hooks/useNow"
import useUpdateInterval from "../../../hooks/useUpdateInterval"
import { useRootStore } from "../../../stores/RootStoreContext"
import ContentError from "../../errors/content-error/ContentError"
import LoadingScreen from "../../loader/LoadingScreen"
import "./Timetable.scss"
import "./TimetableChanges.scss"
import TimetableConfiguration from "./TimetableConfiguration"
import TimetableScheduler from "./TimetableScheduler"

const Timetable = () => {
  const { timetableStore, configurationStore } = useRootStore()
  const now = useNow(UpdateFrequency.Minute)

  useUpdateInterval(
    () => timetableStore.load(),
    [timetableStore.load],
    Constants.UpdateIntervals.UPDATE_TIMETABLE_ALL_SECONDS * 1000,
  )

  if (!configurationStore.display) return null

  const configuration = new TimetableConfiguration(
    configurationStore.display.classConfiguration ?? [],
    configurationStore.display.classLocationFilter,
    configurationStore.display.school,
  )

  return (
    <div className="timetable">
      <LoadingScreen visible={!timetableStore.schedule && timetableStore.isFetching} />
      {timetableStore.schedule
        ? configuration
            .schedulers(timetableStore.schedule, now)
            .map((scheduler) => (
              <TimetableScheduler
                configuration={scheduler.configuration}
                key={scheduler.configuration}
                schedule={scheduler.schedule}
                times={scheduler.times}
              />
            ))
        : null}
      {!timetableStore.schedule && timetableStore.loadingError && (
        <ContentError error={timetableStore.loadingError} />
      )}
    </div>
  )
}

export default observer(Timetable)
