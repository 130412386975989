import APIError, { ErrorType } from "../api/errors/APIError"

class ConfigurationStrings {
  public stringifiedErrorReason(error: APIError) {
    switch (error.type) {
      case ErrorType.BadArguments:
        return "Überprüfen Sie die eingegebene Einrichtungs-URL. Sie muss die Schule sowie die ID des Bildschirms enthalten."
      case ErrorType.Misconfigured:
        return "Das Display exisitiert nicht oder wurde in SALIOREL Manager entfernt. Überprüfen Sie die Einrichtungs-URL."
      case ErrorType.ConfigurationArgumentsMissing:
        return "Dieses Display ist nicht konfiguriert. Mehr Informationen unter manager.saliorel.cloud"
      case ErrorType.NoConnectivity:
        return "Es besteht zurzeit keine Internetverbindung. Überprüfen Sie Ihr Netzwerk."
      default:
        return (
          `Ein technisches Problem ist aufgetreten: ${error.message}` ??
          "Ein unbekannter Fehler ist aufgetreten."
        )
    }
  }
}

export default ConfigurationStrings
