import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useMemo } from "react"
import { ClassConfiguration } from "../../../api/model/Display"
import TimetableSchedule from "../../../api/model/Timetable"
import { useRootStore } from "../../../stores/RootStoreContext"
import OverflowPager from "../../overflow-pager/OverflowPager"
import Timeslot from "./Timeslot"
import TimeslotHeader from "./TimeslotHeader"
import TimetableConfiguration from "./TimetableConfiguration"

interface TimetableScheduleProps {
  configuration: ClassConfiguration
  schedule: TimetableSchedule
  times: string[][]
}

const fixIssuesWithClassName = (className: string) => {
  // Deduplicate if the classname is repeated twice
  // This is releant for some GBW Freifach classes where the class name
  // is incorrectly imported into Intranet Sek II
  if (className.length % 2 === 0) {
    const half = className.length / 2
    if (className.slice(0, half) === className.slice(half)) {
      return className.slice(0, half)
    }
  }
  return className
}

const hasLessonsInTimeslot = (start: string, schedule: TimetableSchedule) =>
  Object.values(schedule).some((classSchedule) => classSchedule[`${start}:00`]?.length > 0)

const trimTimes = (times: string[][], schedule: TimetableSchedule) => {
  let start = 0
  let end = times.length - 1

  while (!hasLessonsInTimeslot(times[start][0], schedule) && start < end) {
    start++
  }
  while (!hasLessonsInTimeslot(times[end][0], schedule) && end > start) {
    end--
  }
  return times.slice(start, end + 1)
}

const TimetableScheduler = (props: TimetableScheduleProps) => {
  const { configurationStore } = useRootStore()
  const classes = useMemo(() => Object.keys(props.schedule ?? {}).sort(), [props.schedule])

  const shouldTrimTimes = props.times.length > 14
  const times = useMemo(
    () => (shouldTrimTimes ? trimTimes(props.times, props.schedule) : props.times),
    [props.times, props.schedule, shouldTrimTimes],
  )
  const compactMode = times.length > 14
  const hasAnyLessons = classes.length > 0

  if (!configurationStore.display) return null

  const configuration = new TimetableConfiguration(
    configurationStore.display.classConfiguration ?? [],
    configurationStore.display.classLocationFilter,
    configurationStore.display.school,
  )

  return (
    <div
      className={classNames("timetable-scheduler", {
        "config-wide": props.configuration !== ClassConfiguration.ShowBaseEducationClassesGBW,
      })}
    >
      {configurationStore.display?.school === "gbw" && (
        <div className="timetable-title">
          {props.configuration === ClassConfiguration.ShowFurtherEducationClassesGBW
            ? "Freifach/Weiterbildung/Lehrgänge"
            : props.configuration === ClassConfiguration.ShowBaseEducationClassesGBW
            ? "Grundbildung"
            : "Stundenplan"}
        </div>
      )}
      {hasAnyLessons ? (
        <>
          <div className="timetable-row row-header">
            <div className="timetable-times">
              {times.map(([start, end], index) => (
                <TimeslotHeader
                  start={start}
                  end={end}
                  index={index}
                  times={times}
                  key={start}
                  compactMode={compactMode}
                />
              ))}
            </div>
          </div>
          <OverflowPager snapToMultiplesOf={93.5}>
            {classes.map((className) => {
              const displayClassName = fixIssuesWithClassName(className)
              const highlight = configuration.highlightForClass(className)
              return (
                <div className={classNames("timetable-row", highlight)} key={className}>
                  <div className="timetable-class">{displayClassName}</div>
                  <div className="timetable-lessons">
                    {times.map(([start]) => (
                      <Timeslot
                        lessons={props.schedule[className]?.[`${start}:00`]}
                        start={start}
                        times={times}
                        key={start}
                        compactMode={compactMode}
                      />
                    ))}
                  </div>
                </div>
              )
            })}
          </OverflowPager>
        </>
      ) : (
        <div>
          Heute finden keine{" "}
          {props.configuration === ClassConfiguration.ShowFurtherEducationClassesGBW
            ? "Weiterbildungen"
            : "Lektionen"}{" "}
          statt.
        </div>
      )}
    </div>
  )
}

export default observer(TimetableScheduler)
