import axios, { AxiosError } from "axios"
import Constants from "./Constants"
import Current from "./Current"
import APIError, { ErrorType } from "./errors/APIError"
import Action from "./model/Action"

class ActionsAPI {
  public async currentActions(): Promise<Action[]> {
    if (!Current.school) return []
    try {
      const response = await axios.get(`${Constants.ServiceHost}/actions/${Current.school}`)
      if (response.status !== 200 || !response.data.actions) throw new Error(response.data.message)
      return response.data.actions as Action[]
    } catch (error: any) {
      throw new APIError(ErrorType.LoadingFailed, (error as AxiosError).message)
    }
  }

  public async fulfillAction(id: string): Promise<void> {
    try {
      const response = await axios.post(
        `${Constants.ServiceHost}/actions/${Current.school}/${id}/fulfill`,
      )
      if (response.status !== 200 || !response.data.action) throw new Error(response.data.message)
    } catch (error: any) {
      throw new APIError(ErrorType.LoadingFailed, (error as AxiosError).message)
    }
  }
}

export default ActionsAPI
