import axios, { AxiosError } from "axios"
import Constants from "./Constants"
import Current from "./Current"
import APIError, { ErrorType } from "./errors/APIError"
import TimetableSchedule from "./model/Timetable"

class TimetableAPI {
  public async scheduleForDate(date: Date): Promise<TimetableSchedule> {
    try {
      const response = await axios.get(
        `${Constants.CoreHost}/timetable/graph/${Current.school}/schedule/${
          date.toISOString().split("T")[0]
        }`,
      )
      if (response.status !== 200) throw new Error(response.data.message)
      return response.data as TimetableSchedule
    } catch (error: any) {
      throw new APIError(ErrorType.LoadingFailed, (error as AxiosError).message)
    }
  }
}

export default TimetableAPI
