import classNames from "classnames"
import { DateTime } from "luxon"
import { observer } from "mobx-react-lite"
import { useMemo } from "react"
import Constants from "../../../api/Constants"
import Event from "../../../api/model/Agenda"
import useUpdateInterval from "../../../hooks/useUpdateInterval"
import { useRootStore } from "../../../stores/RootStoreContext"
import ContentArea from "../../content-grid/ContentArea"
import ContentAreaHeader from "../../content-grid/ContentAreaHeader"
import "./Agenda.scss"

const Agenda = () => {
  const { agendaStore } = useRootStore()

  useUpdateInterval(
    () => agendaStore.load(),
    [agendaStore.load],
    Constants.UpdateIntervals.UPDATE_AGENDA_ALL_SECONDS * 1000,
  )

  const eventsByDay = useMemo(() => {
    const events = agendaStore.events || []
    const today = DateTime.local()

    return events.reduce(
      (acc, event) => {
        const day = acc[event.date] || []
        day.push(event)
        acc[event.date] = day
        return acc
      },
      {
        [today.toFormat("dd.MM.yyyy")]: [],
      } as { [key: string]: Event[] },
    )
  }, [agendaStore.events])

  return (
    <ContentArea className="agenda" grow fadesOff>
      <ContentAreaHeader>Agenda</ContentAreaHeader>
      <div className="calendar">
        {Object.keys(eventsByDay).map((day) => {
          const date = DateTime.fromFormat(day, "dd.MM.yyyy")
          const isThisYear = date.year === DateTime.local().year
          const isToday = day === DateTime.local().toFormat("dd.MM.yyyy")

          const localeDate = date.setLocale("de-CH")
          const formattedMonth = localeDate.toLocaleString({
            month: "long",
            year: isThisYear ? undefined : "numeric",
          })

          return (
            <div key={day} className={classNames("day", { today: isToday })}>
              {isToday ? (
                <div className="date">
                  <span className="natural">Heute</span>
                </div>
              ) : (
                <div className="date">
                  <span className="day-number">{localeDate.day}</span>
                  <span className="month">{formattedMonth}</span>
                </div>
              )}
              <div className="events">
                {eventsByDay[day].length === 0 && isToday && (
                  <div className="no-events-note">
                    <span>Heute sind keine Termine eingetragen.</span>
                  </div>
                )}
                {eventsByDay[day].map((event, index) => (
                  <div key={`${day}-${index}-${event.title}`} className="event">
                    <h3>{event.title}</h3>
                    {!!event.endDate && (
                      <span className="until">
                        {event.date} bis {event.endDate}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </ContentArea>
  )
}

export default observer(Agenda)
