import { makeObservable, observable, runInAction } from "mobx"
import API from "../api/API"
import News from "../api/model/News"
import RootStore from "./RootStore"
import Store from "./Store"

class NewsStore extends Store {
  @observable news: News[] = []

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  public async load() {
    try {
      const news = await API.News.currentNews()
      runInAction(() => {
        this.news = news
        this.rootStore.configurationStore.log("Updated news")
      })
    } catch (error) {
      this.rootStore.configurationStore.log(
        "Could not load current news: " + (error as Error).message,
      )
      console.log(error)
    }
  }
}

export default NewsStore
