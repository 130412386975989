import axios, { AxiosError } from "axios"
import Constants from "./Constants"
import Current from "./Current"
import APIError, { ErrorType } from "./errors/APIError"
import Event from "./model/Agenda"

class AgendaAPI {
  public async currentEvents(): Promise<Event[]> {
    try {
      const response = await axios.get(`${Constants.CoreHost}/agenda/${Current.school}`)
      if (response.status !== 200 || !response.data.events) throw new Error(response.data.message)
      return response.data.events as Event[]
    } catch (error: any) {
      throw new APIError(ErrorType.LoadingFailed, (error as AxiosError).message)
    }
  }
}

export default AgendaAPI
