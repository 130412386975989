import React from "react"
import ConfigurationError from "./components/errors/configuration-error/ConfigurationError"

interface ErrorBoundaryProps {
  children: React.ReactNode
}
interface ErrorBoundaryState {
  hasError?: boolean
  error?: Error
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ConfigurationError isCrash error={this.state.error ?? new Error("Unbekannter Fehler")} />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
