import ActionsAPI from "./ActionsAPI"
import AgendaAPI from "./AgendaAPI"
import ConfigurationAPI from "./ConfigurationAPI"
import MensaAPI from "./MensaAPI"
import NewsAPI from "./NewsAPI"
import PresenceAPI from "./PresenceAPI"
import TimetableAPI from "./TimetableAPI"

class API {
  static Configuration = new ConfigurationAPI()
  static News = new NewsAPI()
  static Mensa = new MensaAPI()
  static Agenda = new AgendaAPI()
  static Timetable = new TimetableAPI()
  static Actions = new ActionsAPI()
  static Presence = new PresenceAPI()
}

export default API
