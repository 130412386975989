import axios, { AxiosError } from "axios"
import URLUtilities from "../utility/URLUtilities"
import Constants from "./Constants"
import APIError, { ErrorType } from "./errors/APIError"
import Configuration from "./model/Configuration"
import Display from "./model/Display"

class ConfigurationAPI {
  public async currentDisplay(): Promise<Display> {
    const { school, identifier } = this.currentConfiguration()
    try {
      const response = await axios.get(
        `${Constants.ServiceHost}/displays/${school}/${identifier}`,
        { transitional: { clarifyTimeoutError: true } },
      )
      if (response.status !== 200 || !response.data.display) {
        if (response.data.message) {
          throw new Error(response.data.message)
        } else {
          throw new Error(response.statusText)
        }
      }
      return response.data.display as Display
    } catch (error: any) {
      throw new APIError(ErrorType.Misconfigured, (error as AxiosError).message, error)
    }
  }

  public currentConfiguration(): Configuration {
    const configString = this.currentConfigurationString
    if (!configString) throw new APIError(ErrorType.ConfigurationArgumentsMissing)

    const config = configString.split("/")
    if (config.length < 2) throw new APIError(ErrorType.BadArguments)

    return { school: config[0], identifier: config[1] }
  }

  private get currentConfigurationString(): string | null {
    const configurationFromQuery = URLUtilities.getURLParameter("configuration")
    if (configurationFromQuery) return configurationFromQuery

    if (document.location.href.indexOf("/v/") > 0) {
      const configurationFromParams = document.location.href.substring(
        document.location.href.indexOf("/v/") + 3,
      )
      return configurationFromParams
    }
    return null
  }
}

export default ConfigurationAPI
