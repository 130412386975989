import React, { useEffect } from "react"
import ClockRenderer from "./ClockRenderer"
import "./Clock.scss"

interface ClockProps {
  size?: number
}

const Clock = (props: ClockProps) => {
  const canvas = React.useRef<HTMLCanvasElement>(null)
  const clockRenderer = React.useRef<ClockRenderer>()
  const clockInterval = React.useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    if (!canvas.current) return

    clockRenderer.current = new ClockRenderer(canvas.current)
    clockInterval.current = setInterval(() => {
      clockRenderer.current?.draw()
    }, 100)

    return () => {
      clearInterval(clockInterval.current)
    }
  }, [])

  const size = props.size || 160

  return (
    <div className="watchface">
      <canvas
        ref={canvas}
        width={size}
        height={size}
        className="clock-view"
        style={{ width: `${size / 20}rem`, height: `${size / 20}rem` }}
      />
    </div>
  )
}

export default Clock
