import { DateTime } from "luxon"

class DateUtilities {
  static isDateBeforeTime(date: DateTime, time: string): boolean {
    return date < this.timeAppliedToDate(date, time)
  }

  static isDateAfterTime(date: DateTime, time: string): boolean {
    return date >= this.timeAppliedToDate(date, time)
  }

  private static timeAppliedToDate(date: DateTime, time: string): DateTime {
    const timeParts = time.split(":")
    return date.set({
      hour: parseInt(timeParts[0]),
      minute: parseInt(timeParts[1]),
    })
  }

  static isDateBetween(date: DateTime, timeStart: string, timeEnd: string): boolean {
    return this.isDateAfterTime(date, timeStart) && this.isDateBeforeTime(date, timeEnd)
  }

  static formatTime(date: DateTime): string {
    const hours = this.padTimeDigit(date.hour)
    const minutes = this.padTimeDigit(date.minute)

    return `${hours}:${minutes}`
  }

  static weekdayText(date: DateTime): string {
    switch (date.weekday) {
      case 1:
        return "Montag"
      case 2:
        return "Dienstag"
      case 3:
        return "Mittwoch"
      case 4:
        return "Donnerstag"
      case 5:
        return "Freitag"
      case 6:
        return "Samstag"
      case 7:
        return "Sonntag"
      default:
        return "–"
    }
  }

  static padTimeDigit(digit: number): string {
    return digit < 10 ? `0${digit}` : `${digit}`
  }
}

export default DateUtilities
