class ClockRenderer {
  private canvas: HTMLCanvasElement

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas

    this.context?.resetTransform()
    this.context?.translate(this.canvas.width / 2, this.canvas.height / 2)
  }

  private get context() {
    return this.canvas.getContext("2d")!
  }

  private get radius() {
    return (this.canvas.height / 2) * 0.95
  }

  public draw() {
    this.context.beginPath()
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)

    this.drawFace()
    this.drawTime()
  }

  private drawFace() {
    this.context.beginPath()
    this.context.fillStyle = "#000"

    this.context.arc(0, 0, this.radius * 0.9, 0, 2 * Math.PI)
    this.context.fill()
  }

  private drawTime() {
    const now = new Date()

    const hour = now.getHours()
    const minute = now.getMinutes()
    const second = now.getSeconds()
    const ms = now.getMilliseconds()

    const angleSecond = ((second + ms / 1000) * Math.PI) / 30
    this.drawHand(angleSecond, this.radius * 0.85, this.radius * 0.05, true)

    const angleHour =
      ((hour % 12) * Math.PI) / 6 + (minute * Math.PI) / (6 * 60) + (second * Math.PI) / (360 * 60)
    this.drawHand(angleHour, this.radius * 0.35, this.radius * 0.08)

    const angleMinute = (minute * Math.PI) / 30 + (second * Math.PI) / (30 * 60)
    this.drawHand(angleMinute, this.radius * 0.6, this.radius * 0.08)
  }

  private drawHand(angle: number, length: number, width: number, isSeconds = false) {
    this.context.beginPath()

    this.context.lineWidth = width
    this.context.lineCap = "round"

    this.context.strokeStyle = "#fff"
    if (isSeconds) {
      this.context.strokeStyle = "#FF0089"
    }

    this.context.moveTo(0, 0)
    this.context.rotate(angle)
    this.context.lineTo(0, -length)

    this.context.stroke()
    this.context.rotate(-angle)
  }
}

export default ClockRenderer
