import { observer } from "mobx-react"
import { useRootStore } from "../../stores/RootStoreContext"
import Agenda from "../content/agenda/Agenda"
import Mensa from "../content/mensa/Mensa"
import News from "../content/news/News"
import Timetable from "../content/timetable/Timetable"

const Content = () => {
  const { configurationStore } = useRootStore()

  const isNewsDisplay = configurationStore.display?.mode === "news"
  const newsConfiguration = configurationStore.display?.newsConfiguration ?? []

  const isMensaEnabled = newsConfiguration.includes("sv-menue")
  const isAgendaEnabled = newsConfiguration.includes("agenda")

  if (!isNewsDisplay) return <Timetable />

  return (
    <>
      {isMensaEnabled && <Mensa />}
      <News fullWidthLayout={!isAgendaEnabled} />
      {isAgendaEnabled && <Agenda />}
    </>
  )
}

export default observer(Content)
