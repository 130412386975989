import API from "../../api/API"
import Constants from "../../api/Constants"
import Current from "../../api/Current"
import Action from "../../api/model/Action"

class ActionsManager {
  private intervalId: number

  constructor() {
    this.intervalId = window.setInterval(
      () => this.onActionInterval(),
      Constants.UpdateIntervals.UPDATE_ACTIONS_ALL_SECONDS * 1000,
    )
  }

  public destroy() {
    window.clearInterval(this.intervalId)
  }

  public onActionInterval() {
    this.fetchActions()
    this.markPresence()
  }

  public async markPresence() {
    await API.Presence.markPresence()
  }

  public async fetchActions() {
    try {
      const actions = await API.Actions.currentActions()
      actions.forEach((action) => this.handleAction(action))
    } catch {
      // We don't care if this fails because we'll try again in a few seconds.
    }
  }

  private isActionTargetedToThisDisplay(action: Action) {
    return action.system === Current.configuration?.identifier || action.system === Current.deviceId
  }

  private async handleAction(action: Action) {
    if (!this.isActionTargetedToThisDisplay(action)) return
    await API.Actions.fulfillAction(action.id)
    switch (action.type) {
      case "reload":
        window.location.reload()
        break
    }
  }
}

export default ActionsManager
