import { makeObservable, observable, runInAction } from "mobx"
import API from "../api/API"
import MensaMenues from "../api/model/Mensa"
import RootStore from "./RootStore"
import Store from "./Store"

class MensaStore extends Store {
  @observable menues: MensaMenues | null = null
  @observable loadingFailed = false
  @observable isMensaMenueVisible = false

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  public async load() {
    try {
      const menues = await API.Mensa.currentMenues()
      runInAction(() => {
        this.menues = menues
        this.loadingFailed = false
      })
    } catch (error) {
      this.loadingFailed = true
      this.rootStore.configurationStore.log("Could not load menue: " + (error as Error).message)
      console.log(error)
    }
  }
}

export default MensaStore
