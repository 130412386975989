import React from "react"
import NewsElementProps from "./NewsElementProps"
import "./NewsCalloutElement.scss"

const NewsCalloutElement = (props: NewsElementProps) => (
  <div className="news-element element-callout">
    <div className="callout-text">{props.news.title}</div>
  </div>
)

export default NewsCalloutElement
