import { DateTime } from "luxon"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import Constants from "../../../api/Constants"
import MensaIcon from "../../../assets/icons/panel-mensa.svg"
import useNow, { UpdateFrequency } from "../../../hooks/useNow"
import useUpdateInterval from "../../../hooks/useUpdateInterval"
import { useRootStore } from "../../../stores/RootStoreContext"
import ContentArea from "../../content-grid/ContentArea"
import ContentAreaHeader from "../../content-grid/ContentAreaHeader"
import "./Mensa.scss"
import MensaMenue from "./MensaMenue"

const matchesContentDisplayTime = (now: DateTime) => {
  let matches = false
  Constants.ContentDisplayTimes.MENSA.forEach((timeConfiguration) => {
    const { start, end } = timeConfiguration

    const [startHour, startMinute] = start
    const [endHour, endMinute] = end

    const hasStarted = now.hour > startHour || (now.hour === startHour && now.minute >= startMinute)
    const hasEnded = now.hour > endHour || (now.hour === endHour && now.minute >= endMinute)

    if (hasStarted && !hasEnded) matches = true
  })
  return matches
}

export const MENSA_HEIGHT = 302

const Mensa = () => {
  const { mensaStore } = useRootStore()
  const now = useNow(UpdateFrequency.Minute)

  useUpdateInterval(
    () => mensaStore.load(),
    [mensaStore.load],
    Constants.UpdateIntervals.UPDATE_MENSA_ALL_SECONDS * 1000,
  )

  const shouldDisplayMensaAtThisTime = matchesContentDisplayTime(now)

  useEffect(() => {
    runInAction(() => {
      mensaStore.isMensaMenueVisible = shouldDisplayMensaAtThisTime
    })
  }, [shouldDisplayMensaAtThisTime, mensaStore])

  if (!shouldDisplayMensaAtThisTime) return null

  return (
    <ContentArea className="mensa">
      <ContentAreaHeader icon={MensaIcon}>Heute in der Mensa</ContentAreaHeader>
      <div className="menues">
        {mensaStore.loadingFailed ? (
          <div>Der aktuelle Mensaplan kann nicht angezeigt werden..</div>
        ) : (
          mensaStore.menues?.day_0.menues.map((menue, index) => (
            <MensaMenue key={`${menue.text.join("")}-${index}`} menue={menue} />
          ))
        )}
      </div>
    </ContentArea>
  )
}

export default observer(Mensa)
