export type DisplayMode = "timetable" | "news" | "dual"
export type NewsConfigurationFeature = "sv-menue" | "agenda" | "debug"

export enum ClassConfiguration {
  ShowBaseEducationClassesGBW = "gbw-grundbildung",
  ShowFurtherEducationClassesGBW = "gbw-weiterbildung",
  Any = "all",
}

interface Display {
  id: string
  identifier: string
  mode: DisplayMode
  dayShift: string
  school: string
  depiction: string
  presentationMode: string
  layoutMode: string
  classConfiguration?: ClassConfiguration[]
  classLocationFilter?: string[]
  newsConfiguration?: NewsConfigurationFeature[]
  displayTitle?: string
  isDarkened?: boolean
  dualScreens?: string[]
}

export default Display
