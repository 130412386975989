import useNow, { UpdateFrequency } from "../../../hooks/useNow"
import DateUtilities from "../../../utility/DateUtilities"

const Time = () => {
  const now = useNow(UpdateFrequency.High)
  return (
    <div className="time">
      <span className="hour">{now.hour}</span>
      <span className="colon">:</span>
      <span className="minutes">{DateUtilities.padTimeDigit(now.minute)}</span>
    </div>
  )
}

export default Time
