import classNames from "classnames"
import { ScheduleLesson } from "../../../api/model/Timetable"

interface LessonProps {
  lesson: ScheduleLesson
}

const Lesson = (props: LessonProps) => {
  const { lesson } = props
  if (lesson.isContinuation) return null

  const hasRoomChange = lesson.type === "rmchg"
  const isCanceled = lesson.type === "cancel"

  return (
    <div
      className={classNames("lesson", {
        "has-room-change": hasRoomChange,
        "is-canceled": isCanceled,
      })}
    >
      <div className="lesson-title">{lesson.title}</div>
      <div className="lesson-room">{lesson.room}</div>
      <div className="lesson-teacher">
        {isCanceled ? <span className="cancel-notice">Ausfall</span> : lesson.teacher}
      </div>
    </div>
  )
}

export default Lesson
