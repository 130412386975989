import classNames from "classnames"
import { ScheduleLesson } from "../../../api/model/Timetable"
import useNow, { UpdateFrequency } from "../../../hooks/useNow"
import DateUtilities from "../../../utility/DateUtilities"
import Lesson from "./Lesson"

interface TimeslotProps {
  start: string
  times: string[][]
  lessons: ScheduleLesson[] | undefined
  compactMode?: boolean
}

const findBlockEndTime = (start: string, duration: number, times: string[][]) => {
  const startTimeIndex = times.findIndex(([s]) => s === start)
  const endTimeIndex = startTimeIndex + duration - 1

  return times[endTimeIndex]?.[1]
}

const findPreviousEndTime = (start: string, times: string[][]) => {
  const startTimeIndex = times.findIndex(([s]) => s === start)
  const previousTimeIndex = startTimeIndex - 1

  return times[previousTimeIndex]?.[1]
}

const Timeslot = (props: TimeslotProps) => {
  const now = useNow(UpdateFrequency.EverySecond)

  const { times, start, lessons } = props

  const isEmpty = !lessons || lessons?.length === 0
  const onlyContainsContinuations = !isEmpty && lessons?.every((lesson) => lesson.isContinuation)

  const onlyContainsCancelations = !isEmpty && lessons?.every((lesson) => lesson.type === "cancel")
  const onlyContainsRoomChanges = !isEmpty && lessons?.every((lesson) => lesson.type === "rmchg")

  let duration = Math.min(...(lessons?.map((lesson) => lesson.duration ?? 1) ?? [1]))
  if (duration === Infinity) duration = 1

  const blockEnd = findBlockEndTime(start, duration, times)
  const previousTimeEnd = findPreviousEndTime(start, times)

  const isPast = blockEnd ? DateUtilities.isDateAfterTime(now, blockEnd) : false
  const isNow = blockEnd ? DateUtilities.isDateBetween(now, start, blockEnd) : false
  const isNext =
    !isPast &&
    !isNow &&
    previousTimeEnd !== undefined &&
    DateUtilities.isDateAfterTime(now, previousTimeEnd)

  if (onlyContainsContinuations) return null
  return (
    <div
      className={classNames("lesson-timeslot", duration > 1 ? `spans-${duration}` : undefined, {
        empty: !lessons || lessons.length === 0,
        "multiple-lessons": !isEmpty && lessons.length > 1,
        now: isNow || isNext,
        past: isPast,
        compact: props.compactMode,
        "all-canceled": onlyContainsCancelations,
        "all-room-changes": onlyContainsRoomChanges,
      })}
      key={start}
      style={{
        width: `calc(${(100 / times.length) * duration}% + ${(duration - 1) * 0.4}rem)`,
      }}
    >
      {lessons?.map((lesson, index) => (
        <Lesson lesson={lesson} key={lesson.start + lesson.title + index} />
      ))}
    </div>
  )
}

export default Timeslot
