class URLUtilities {
  static getURLParameter(name: string) {
    const url = window.location.href
    const regex = new RegExp(`[?&]${name}=([^&#]*)`)
    const results = regex.exec(url)
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "))
  }
}

export default URLUtilities
