import classNames from "classnames"
import Logo from "../header/Logo"
import "./LoadingScreen.scss"

interface LoadingScreenProps {
  visible: boolean
}

const LoadingScreen = (props: LoadingScreenProps) => (
  <div className={classNames("loading-screen", { visible: props.visible })}>
    <div className="overlay" />
    <div className="content">
      <div className="loading-brand">
        <Logo large />
      </div>
      <div className="loading-bar">
        <div className="bar-inner" />
      </div>
    </div>
  </div>
)

export default LoadingScreen
