import AgendaStore from "./AgendaStore"
import ConfigurationStore from "./ConfigurationStore"
import MensaStore from "./MensaStore"
import NewsStore from "./NewsStore"
import TimetableStore from "./TimetableStore"

export class RootStore {
  configurationStore: ConfigurationStore
  newsStore: NewsStore
  mensaStore: MensaStore
  agendaStore: AgendaStore
  timetableStore: TimetableStore

  constructor() {
    this.configurationStore = new ConfigurationStore(this)
    this.newsStore = new NewsStore(this)
    this.mensaStore = new MensaStore(this)
    this.agendaStore = new AgendaStore(this)
    this.timetableStore = new TimetableStore(this)
  }
}

export default RootStore
