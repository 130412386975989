import useNow, { UpdateFrequency } from "../../hooks/useNow"
import DateUtilities from "../../utility/DateUtilities"
import Clock from "./clock/Clock"
import Time from "./clock/Time"
import "./Header.scss"
import Logo from "./Logo"

const Header = () => {
  const today = useNow(UpdateFrequency.Low)
  return (
    <div className="header">
      <div className="brand">
        <Logo />
      </div>
      <div className="date">
        <span className="weekday">{DateUtilities.weekdayText(today)}</span>
        <span className="full-date">
          {today.setLocale("de-CH").toLocaleString({ dateStyle: "long" })}
        </span>
      </div>
      <div className="clock">
        <Time />
        <Clock />
      </div>
    </div>
  )
}

export default Header
