import classNames from "classnames"
import React from "react"

interface ContentAreaProps {
  className?: string
  children?: React.ReactNode
  minHeight?: number
  grow?: boolean
  fadesOff?: boolean
}

const ContentArea = (props: ContentAreaProps) => (
  <div
    className={classNames("content-area", props.className, { "fades-off": props.fadesOff })}
    style={{
      minHeight: props.minHeight ? `${props.minHeight / 10}rem` : undefined,
      flexGrow: props.grow ? 1 : undefined,
    }}
  >
    {props.children}
    {props.fadesOff && <div className="content-area-fade" />}
  </div>
)

export default ContentArea
