import axios, { AxiosError } from "axios"
import Constants from "./Constants"
import Current from "./Current"
import APIError, { ErrorType } from "./errors/APIError"
import News from "./model/News"

class NewsAPI {
  public async currentNews(): Promise<News[]> {
    try {
      const response = await axios.get(`${Constants.ServiceHost}/news/${Current.school}`)
      if (response.status !== 200 || !response.data.news) throw new Error(response.data.message)
      return response.data.news as News[]
    } catch (error: any) {
      throw new APIError(ErrorType.LoadingFailed, (error as AxiosError).message)
    }
  }
}

export default NewsAPI
