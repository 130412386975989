import { useEffect } from "react"
import APIError from "../../../api/errors/APIError"
import Strings from "../../../strings/Strings"
import "./ConfigurationError.scss"

interface ConfigurationErrorProps {
  isCrash?: boolean
  error: APIError | Error
}

const AUTOMATIC_RESTART_AFTER_SECONDS = 30

const ConfigurationError = (props: ConfigurationErrorProps) => {
  useEffect(() => {
    const restart = setTimeout(() => {
      window.location.reload()
    }, AUTOMATIC_RESTART_AFTER_SECONDS * 1000)
    return () => clearTimeout(restart)
  }, [])

  return (
    <div className="configuration-error">
      <div className="error">
        <div className="error-depiction">
          <h1 className="error-title">
            {props.isCrash ? "Technischer Fehler" : "Konfigurationsfehler"}
          </h1>
          <p className="error-reason">
            {"type" in props.error
              ? Strings.Configuration.stringifiedErrorReason(props.error)
              : props.error.message}
          </p>
          <p className="error-help">
            SALIOREL Display wird automatisch einen Neustart versuchen. Die Schulwebseite sowie die
            saliorel.com-Webseite können in der Zwischenzeit verwendet werden, während das Problem
            behoben wird.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ConfigurationError
