import classNames from "classnames"
import useNow, { UpdateFrequency } from "../../../hooks/useNow"
import DateUtilities from "../../../utility/DateUtilities"

interface TimeslotHeaderProps {
  times: string[][]
  start: string
  end: string
  index: number
  compactMode?: boolean
}

const TimeslotHeader = (props: TimeslotHeaderProps) => {
  const now = useNow(UpdateFrequency.EverySecond)
  const { times, start, end, index } = props

  const isPast = DateUtilities.isDateAfterTime(now, end)
  const isNow = DateUtilities.isDateBetween(now, start, end)

  const previousTimeEnd = times[index - 1]?.[1]
  const isNext =
    !!previousTimeEnd && !isPast && !isNow && DateUtilities.isDateAfterTime(now, previousTimeEnd)

  return (
    <div
      className={classNames("header-timeslot", {
        now: isNow || isNext,
        past: isPast,
      })}
      key={start}
      style={{ width: `calc(${100 / times.length}%` }}
    >
      {start}
      {!props.compactMode && `–${end}`}
    </div>
  )
}

export default TimeslotHeader
