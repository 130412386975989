import NewsCalloutElement from "./NewsCalloutElement"
import "./NewsElement.scss"
import NewsElementProps from "./NewsElementProps"
import NewsImageElement from "./NewsImageElement"
import NewsItemElement from "./NewsItemElement"
import NewsVideoElement from "./NewsVideoElement"

const NewsElement = (props: NewsElementProps) => {
  switch (props.news.type) {
    case "image":
      return <NewsImageElement news={props.news} />
    case "callout":
      return <NewsCalloutElement news={props.news} />
    case "news-item":
      return <NewsItemElement news={props.news} />
    case "video":
      return <NewsVideoElement news={props.news} />
    default:
      return null
  }
}

export default NewsElement
