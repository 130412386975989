import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import App from "./App"
import "./index.scss"
import RootStoreProvider from "./stores/RootStoreContext"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <RootStoreProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<App />} />
            <Route path="v/:school/:identifier" element={<App />} />
            <Route path="dual-screen/v/:school/:identifier" element={<App isDualScreenElement />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </RootStoreProvider>
  </React.StrictMode>,
)
