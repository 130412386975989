import { ClassConfiguration } from "./model/Display"

class Constants {
  static Version = "2.2.06"

  static ServiceHost = "https://service.saliorel.cloud"
  static CoreHost = "https://core-3.saliorel.cloud"

  static UpdateIntervals = {
    UPDATE_NEWS_ALL_SECONDS: 15,
    UPDATE_MENSA_ALL_SECONDS: 600,
    UPDATE_AGENDA_ALL_SECONDS: 600,
    UPDATE_TIMETABLE_ALL_SECONDS: 600,
    UPDATE_ACTIONS_ALL_SECONDS: 15,
  }

  static ContentDisplayTimes = {
    MENSA: [
      { start: [9, 0], end: [10, 0] },
      { start: [11, 30], end: [13, 0] },
    ],
    GBW_TIMETABLE_DEFAULT_CONFIGS: [
      { start: "0:00", configurations: [ClassConfiguration.ShowBaseEducationClassesGBW] },
      { start: "0:00", configurations: [ClassConfiguration.ShowFurtherEducationClassesGBW] },
    ],
  }

  static TimetableTimes = {
    GBW_GB: [
      ["07:10", "07:55"],
      ["08:05", "08:50"],
      ["08:55", "09:40"],
      ["10:00", "10:45"],
      ["10:50", "11:35"],
      ["11:40", "12:25"],
      ["12:35", "13:20"],
      ["13:25", "14:10"],
      ["14:15", "15:00"],
      ["15:15", "16:00"],
      ["16:05", "16:50"],
      ["16:55", "17:40"],
    ],
    GBW_WB: [
      ["07:10", "07:55"],
      ["08:05", "08:50"],
      ["08:55", "09:40"],
      ["10:00", "10:45"],
      ["10:50", "11:35"],
      ["11:40", "12:25"],
      ["12:35", "13:20"],
      ["13:25", "14:10"],
      ["14:15", "15:00"],
      ["15:15", "16:00"],
      ["16:05", "16:50"],
      ["16:55", "17:40"],
      ["17:45", "18:30"],
      ["18:35", "19:20"],
      ["19:25", "20:10"],
      ["20:15", "21:00"],
      ["21:05", "21:50"],
    ],
  }

  static ClassConfigurationPrefixes = {
    GBW_FURTHER_EDUCATION: [
      "A-",
      "B-",
      "BL",
      "FB-",
      "HW",
      "S-",
      "Z-",
      "ZH",
      "GGL",
      "GGB",
      "GVK",
      "GFA",
      "E-",
      "EP",
      "Rep",
      "TREP",
      "MAP",
    ],
    GBW_LOCATIONS_FILTER: [],
  }
}

export default Constants
