import React from "react"
import "./NewsEmptyState.scss"

const NewsEmptyState = () => (
  <div className="news-empty">
    <span>Hier werden relevante Informationen und Ankündigungen angezeigt.</span>
  </div>
)

export default NewsEmptyState
